// Default imports
import React from "react";

import { Row, Col } from "react-bootstrap";
import ContainerWide from "../ContainerWide";

const PageHeadConnectivity = ({ title, text, types }) => {
  return (
    <ContainerWide className="connectivity">
      <Row>
        <Col xs="24">
          <h1 className="connectivity__header">{title}</h1>
          {types && (
            <Row className="connectivity__wrapper">
              {types.map(({ header, item }, index) => (
                <Col
                  key={index}
                  xs="24"
                  lg="6"
                  className="connectivity__header-item"
                >
                  <h2 className="connectivity__title">{header}</h2>
                  <p className="connectivity__text">
                    {item.map(({ text }, index) => (
                      <span key={index}>
                        {text}
                        {index + 1 < item.length ? " | " : ""}
                      </span>
                    ))}
                  </p>
                </Col>
              ))}
            </Row>
          )}
          <Row className="connectivity__wrapper">
            <Col xs="24" lg="18">
              <p className="connectivity__text">{text}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerWide>
  );
};

export default PageHeadConnectivity;
