import React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { connectivityPageTplData } from "../data/page-tpl-connectivity.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import { Container } from "react-bootstrap";
import Section from "../components/Section";

import Connectivity from "../components/Connectivity";
import PageHeadConnectivity from "../components/Page/PageHeadConnectivity";
import Layout from "../components/Layout";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";

const pageConnectivity = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = connectivityPageTplData(id);

  return (
    <Layout>
      {/* SEO Metas */}
      <Seo post={seoPageData} />

      {/* Connectivity Section */}
      <Section>
        <Container>
          <PageHeadConnectivity {...pageData.pageHeader} />
          <Connectivity />
        </Container>
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch />
      </Section>

      {/* News and insights Section */}

      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default pageConnectivity;
