import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const connectivityCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        # Connectivity Posts
        allWpConnectivity {
          nodes {
            id
            menuOrder
            connectivityCategories {
              nodes {
                slug
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
            cptConnectivity {
              bankingpartner
              lists {
                header
                list {
                  item
                }
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData
                  }
                  childSvg {
                    content {
                      data
                    }
                  }
                }
              }
            }
          }
        }

        # Connectivity Categories
        allWpConnectivityCategory {
          nodes {
            id
            name
            description
            slug
            count
            parentId
            wpParent {
              node {
                slug
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export { connectivityCompData };
