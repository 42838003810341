import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const connectivityPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Connectivity {
                  connectivityRichContent {
                    # SECTION: Page Header
                    pageHeader {
                      title
                      text
                      types {
                        header
                        item {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "connectivityRichContent");
};

export { connectivityPageTplData };
