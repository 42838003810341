// Default imports
import React, { useState, useEffect } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import { connectivityCompData } from "../data/components-connectivity.data";

import ContainerWide from "./ContainerWide";
import { TabLabels } from "./Tabs";
import Tiles from "./Tiles";

const Connectivity = () => {
  const [preventScroll, setPreventScroll] = useQueryParam("prsc", StringParam);

  const compData = connectivityCompData();

  const [activeParentCat = "crypto", setActiveParentCat] = useQueryParam(
    "parentcat",
    StringParam
  );
  const [activeCat, setActiveCat] = useQueryParam("cat", StringParam);

  const handleTabClick = ({ event, id }) => {
    event.preventDefault();
    setPreventScroll(true);

    setActiveCat(id);
  };

  const handleParentTabClick = ({ event, id }) => {
    event.preventDefault();
    setPreventScroll(true);

    setActiveParentCat(id);
    setActiveCat();
  };

  /**
   * TABS
   *
   * Spread all the categories with new kets mapping
   */
  const parentTabs = compData.allWpConnectivityCategory.nodes
    .filter((node) => node.parentId === null)
    .map((node) => {
      return { name: node.name, id: node.slug };
    });

  const tabs = [
    { name: "all" },
    ...compData.allWpConnectivityCategory.nodes
      .filter((node) => {
        return node.count > 0 && node.wpParent.node.slug === activeParentCat;
      })
      .map((node) => {
        return { ...node, name: node.name, id: node.slug, order: Number(node.description) || 0 };
      })
      .sort((a, b) => a.order - b.order),
  ];

  const contacts = compData.allWpConnectivity.nodes
    .filter(({ connectivityCategories: { nodes } }) => {
      return (
        (!activeCat || nodes[0].slug === activeCat) &&
        nodes[0].wpParent.node.slug === activeParentCat
      );
    })
    .map((node) => {
      return {
        bankingPartner: node.cptConnectivity.bankingpartner,
        lists: node.cptConnectivity.lists,
        coverImage: node.featuredImage?.node,
        cat: node.connectivityCategories.nodes[0].slug,
        order: node.menuOrder,
        // parentCat: node.connectivityCategories.nodes[0].parentId,
      };
    })
    .sort((a, b) => {
      return Number(a.order) < Number(b.order) ? -1 : 1;
    });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  });

  return (
    <ContainerWide>
      {/* Top level tabs list */}
      <TabLabels
        className="pb-3"
        buttonVariant="small,deepblue,underline"
        labels={parentTabs.map((item) => ({
          label: item.name,
          active: item.id === activeParentCat,
          onClick: (e) => handleParentTabClick({ event: e, id: item.id }),
        }))}
      />

      {/* Tabs list */}
      <TabLabels
        className="py-2"
        buttonVariant="small,deepblue"
        labels={tabs.map((item) => ({
          label: item.name,
          active: item.id === activeCat,
          onClick: (e) => handleTabClick({ event: e, id: item.id }),
        }))}
      />

      {/* Connectivity */}
      <Tiles
        items={contacts}
        activeCat={activeCat}
        isLoaded={isLoaded}
        className="-connectivity-box"
      />
    </ContainerWide>
  );
};

export default Connectivity;
